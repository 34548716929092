let rol = localStorage.getItem('rol');
let Menu = [];

switch(rol){
    case 'rol-admin':
        Menu = [
            {
                name: 'Biblioteca',
                path: '/bibliotecacadem',
                icon: 'img/icons/book.svg',
                label: { value: 1, classNme: 'bg-success' }
            } ,
            {
                name: 'Mantenedores',
                icon: 'img/icons/navicon.svg',
                submenu: [
                    {
                        name: 'Clientes',
                        path: '/mantenedorclientes'
                    },
                    {
                        name: 'DP',
                        path: '/mantenedordp'
                    },
                    {
                        name: 'Usuarios',
                        path: '/usuarios'
                    },
                ]
            },
        ];
    break;
    case 'rol-gestion':
        Menu = [
            {
                name: 'Biblioteca',
                path: '/bibliotecacadem',
                icon: 'img/icons/book.svg',
                label: { value: 1, classNme: 'bg-success' }
            } ,
            {
                name: 'Mantenedores',
                icon: 'img/icons/navicon.svg',
                submenu: [
                    {
                        name: 'Clientes',
                        path: '/mantenedorclientes'
                    },
                    {
                        name: 'DP',
                        path: '/mantenedordp'
                    }
                ]
            },
        ];
    break;
}


export default Menu;
