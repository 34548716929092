import React, { useCallback, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import CircularProgress from '@material-ui/core/CircularProgress';
import { checkLocalToken } from '../../services/UserSSOService';
import { completeSsoRedirect } from '../../configuration';
import './styless.css';

export const LoginRequireScreen = () => {
  const [ cookies ] = useCookies(['token', 'rol']);
  const { token, rol } = cookies;

  const executeCheck = useCallback(async () => {
    localStorage.setItem('rol', rol);
    const data = await checkLocalToken();

    if(data.ok){
      window.location.href = "/downloadfiles";
    }
  }, [rol])

  useEffect(() => {
    if(token){
      executeCheck();
    }else{
      localStorage.clear();
      window.location.href = completeSsoRedirect;
    }
  }, [ token, executeCheck ])

  return (
    <div className="unauthorized-content">
      <CircularProgress />
    </div>
  )
}
