export const getQueryParamsAndSave = ( ) => {
  const url = window.location.href; 

  const queryParams = new URLSearchParams(new URL(url).search)

  const paramNames = ['token', 'rol']

  paramNames.forEach((name) =>{
    const value = queryParams.get(name)
    if (value){
      const expires = new Date(Date.now() + 4 * 3600 * 1000).toUTCString();
      //cookies duration 4 hours

      document.cookie = `${name}=${value}; expires=${expires}; path=/; Secure; SameSite=Lax`;
    }
  })
}
