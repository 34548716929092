import React, { Component, useReducer, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import {unregister } from './interceptor';

// Vendor dependencies
import './Vendor';

// App Routes
import Routes from './Routes';

// Context and Reducer
// import { ListFiltrosContext } from './views/MarcoMuestral/ListFiltrosContext';
// import { listFiltrosReducer } from './views/MarcoMuestral/listFiltrosReducer';
import './components/Ripple/Ripple.init.js';

const App = () => {
  // const basename = process.env.NODE_ENV === 'development' ? '/' : (PUBLIC_URL || '/');  
  const basename = process.env.NODE_ENV === 'development' ? '/' : '/';
            
    return (
        <BrowserRouter basename={basename}>
          <Routes />
        </BrowserRouter>
    );

}

export default App;


