import React, { Suspense, lazy, useEffect } from 'react';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

/* loader component for Suspense */
import PageLoader from './components/Common/PageLoader';
/* eslint-disable-next-line */
import Core from './components/Core/Core';
/* eslint-disable-next-line */
import Bootstrap from './components/Bootstrap/Bootstrap';
/* eslint-disable-next-line */
import Common from './components/Common/Common';
/* eslint-disable-next-line */
import Colors from './components/Colors/Colors';
/* eslint-disable-next-line */
import FloatButton from './components/FloatButton/FloatButton';
/* eslint-disable-next-line */
import Utils from './components/Utils/Utils';

import { LoginRequireScreen } from './views/NoLoginCP/LoginRequireScreen';
import { UnauthorizedScreen } from './views/NoLoginCP/UnauthorizedScreen';
import { getCookieByName } from './helpers/getCookieByName';
import { getQueryParamsAndSave } from './helpers/getQueryParams';
import { checkLocalToken } from './services/UserSSOService';
/* Used to render a lazy component with react-router */
const waitFor = Tag => props => <Tag {...props}/>;
const Biblioteca = lazy(() => import('./views/Biblioteca/Biblioteca'));
const MantenedorClientes = lazy(() => import('./views/Clientes/MantenedorClientes'));
const MantenedorDp = lazy(() => import('./views/Dp/MantenedorDP'));
const MantenedorUsuarios = lazy(() => import('./views/Usuarios/MantenedorUsuarios'));
const UsersMainScreen = lazy(() => import('./views/UserSSO/UsersMainScreen'));
const PageNotFound = lazy(() => import('./views/Errors/PageNotFound'));

const Routes = ({ location }) => {
    getQueryParamsAndSave();
    const currentKey = location.pathname.split('/')[1] || '/';
    const timeout = { enter: 500, exit: 500 };
    const animationName = 'rag-fadeIn'

    const localToken = getCookieByName() || '';
    const logged = localStorage.getItem('log-ged') || '';
    const rolUser = localStorage.getItem('rol') || '';

    useEffect(() => {
        if(location.pathname !== "/unauthorized"){
            checkLocalToken()
        }
    }, [ location.pathname ])

    if(logged){
        return (
            <Suspense fallback={<PageLoader/>}>
                <Switch>
                    <Route exact path="/unauthorized" component={UnauthorizedScreen} />
                    <Redirect to="/unauthorized" />
                </Switch>
            </Suspense>
        )
    }

    if(!localToken || !rolUser){
        return (
            <Suspense fallback={<PageLoader/>}>
                <Switch>
                    <Route exact path="/" component={LoginRequireScreen} />
                    <Route exact path="/unauthorized" component={UnauthorizedScreen} />
                    <Redirect to="/"/>
                </Switch>
            </Suspense>
        )
    }


    if(rolUser === 'rol-admin'){
        return (
            <Core>
                <TransitionGroup>
                <CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
                    <div>
                        <Suspense fallback={<PageLoader/>}>
                            <Switch location={location}>
                                <Route path="/bibliotecacadem" component={waitFor(Biblioteca)}/>
                                <Route path="/mantenedorclientes" component={waitFor(MantenedorClientes)}/>
                                <Route path="/mantenedordp" component={waitFor(MantenedorDp)}/>
                                <Route path="/mantenedorusuarios" component={waitFor(MantenedorUsuarios)}/>
                                <Route path="/usuarios" component={waitFor(UsersMainScreen)}/>
                                <Route path="/PageNotFound" component={waitFor(PageNotFound)}/>
                                <Redirect to="/bibliotecacadem"/>
                            </Switch>
                        </Suspense>
                    </div>
                </CSSTransition>
                </TransitionGroup>
            </Core>
        )
    }

    if(rolUser === 'rol-gestion'){
        return (
            <Core>
                <TransitionGroup>
                <CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
                    <div>
                        <Suspense fallback={<PageLoader/>}>
                            <Switch location={location}>
                                <Route path="/bibliotecacadem" component={waitFor(Biblioteca)}/>
                                <Route path="/mantenedorclientes" component={waitFor(MantenedorClientes)}/>
                                <Route path="/mantenedordp" component={waitFor(MantenedorDp)}/>
                                <Route path="/bibliotecacadem" component={waitFor(PageNotFound)}/>
                            </Switch>
                        </Suspense>
                    </div>
                </CSSTransition>
                </TransitionGroup>
            </Core>
        )
    }
}

export default withRouter(Routes);
